<template>
	<div>
		<h1>
			<template v-if="session.identity">Welkom, {{ session.identity.roepNaam }}!</template>
			<template v-else>Welkom</template>
		</h1>

		<p v-if="!session.identity">Klik links op 'Inloggen' om in te loggen met je Dialogic-account!</p>

		<template v-if="session.identity">
			<p>
				Welkom bij de urenapplicatie van Dialogic. In dit systeem wordt alles bijgehouden dat te maken heeft met uren:
				projecten en projectbudgetten, dienstverbanden en verlofuren. Via de balk aan de linkerkant kun je verschillende
				onderdelen bekijken.
			</p>

			<Challenges />

			<h2>Snel naar...</h2>
			<ul class="menu">
				<li>
					<NuxtLink :to="'/medewerker/' + encodeURIComponent(medewerker)">🏠 Mijn overzicht</NuxtLink>
				</li>
				<li>
					<NuxtLink :to="'/medewerker/' + encodeURIComponent(medewerker) + '/' + new Date().getFullYear() + '/' + week"
						>🗳 Urenbriefje van deze week ({{ week }})</NuxtLink
					>
				</li>
			</ul>

			<template v-if="hasExtraRights">
				<h2>Portefeuilles</h2>
				<ul class="menu">
					<li v-if="isMT || isAcquisitie"><NuxtLink to="/acquisitie">☕ &nbsp;Acquisitie</NuxtLink></li>
					<li v-if="isMT || isAdministratie">
						<NuxtLink to="/administratie">📔 &nbsp;Administratie</NuxtLink>
					</li>
					<li v-if="isSalaris">
						<NuxtLink to="/beloning">💰&nbsp; Beloning</NuxtLink>
					</li>

					<li v-if="isMT || isCommunicatie"><NuxtLink to="/communicatie">📣 &nbsp;Communicatie</NuxtLink></li>
					<li v-if="isMT || isICT"><NuxtLink to="/ict">👨🏻‍💻&nbsp; ICT</NuxtLink></li>
					<li v-if="isKwaliteit || isMT">
						<NuxtLink to="/kwaliteit">💯&nbsp; Kwaliteitsmanagement</NuxtLink>
					</li>
					<li v-if="!(isMT || isHRM) && isAdresGegevens">
						<NuxtLink to="/hrm/medewerkers">👨‍👩‍👧‍👦 &nbsp;Medewerkers</NuxtLink>
					</li>
					<li v-if="!(isMT || isHRM) && isAdresGegevens">
						<NuxtLink to="/hrm/ovabonnementen">🚂&nbsp;OV-abonnementen</NuxtLink>
					</li>
					<li v-if="isMT || isHRM"><NuxtLink to="/hrm">💪🏻 &nbsp;Personeel en organisatie</NuxtLink></li>
					<li v-if="isMT"><NuxtLink to="/mt">📚 &nbsp;Projectuitvoering</NuxtLink></li>
					<li v-if="isSalaris && isMT">
						<NuxtLink to="/resultaat">🍰&nbsp; Resultaat</NuxtLink>
					</li>
				</ul>
			</template>

			<h2 v-if="hasExtraRights">Overige rapportages</h2>
			<h2 v-else>Algemene informatie</h2>
			<ul class="menu">
				<li><NuxtLink to="/prestaties">🏅&nbsp;Hoe staan we ervoor?</NuxtLink></li>
				<li><NuxtLink to="/basistarieven">🏷 &nbsp;Basistarieven</NuxtLink></li>
				<li><NuxtLink to="/telefoonboek">☎️ &nbsp;Telefoonlijst</NuxtLink></li>
				<li><NuxtLink to="/verjaardagen">🎂 &nbsp;Verjaardagskalender</NuxtLink></li>
				<li>
					<NuxtLink to="/gepland-verlof">📆&nbsp; Verlofplanning</NuxtLink>
				</li>

				<li>
					<NuxtLink to="/werkvoorraad">📦&nbsp; Werkvoorraad</NuxtLink>
				</li>
			</ul>

			<template v-if="session.identity && session.identity.privileges.length > 0">
				<h2>Speciaal</h2>
				<ul class="menu">
					<li v-if="isMT">
						<form method="POST" action="/api/session/drop">
							<a href="javascript:void(0);" @click="$event.target.parentNode.submit()"
								>🎭&nbsp; Herinloggen zonder speciale privileges</a
							>
						</form>
					</li>
				</ul>
			</template>

			<footer>
				Lettertype voor getallen:
				<a class="monospace" href="javascript:void(0)" @click="monospace = !monospace">{{
					monospace ? "vaste breedte" : "normaal"
				}}</a>

				Weergave:
				<a class="monospace" href="javascript:void(0)" @click="isDarkModePreferred = !isDarkModePreferred">{{
					isDarkModePreferred ? "dark side" : "normaal"
				}}</a>
			</footer>
		</template>
	</div>
</template>
<script setup>
import {getISOWeek} from "~~/shared";

const session = inject("session");
const isMT = inject("isMT");
const isAdministratie = inject("isAdministratie");
const isCommunicatie = inject("isCommunicatie");
const isHRM = inject("isHRM");
const isAcquisitie = inject("isAcquisitie");
const isICT = inject("isICT");
const isSalaris = inject("isSalaris");
const medewerker = inject("medewerker");
const isAdresGegevens = inject("isAdresGegevens");
const isKwaliteit = inject("isKwaliteit");
const week = getISOWeek(new Date());

const hasExtraRights = computed(() => {
	return (
		isMT.value ||
		isAdministratie.value ||
		isCommunicatie.value ||
		isHRM.value ||
		isICT.value ||
		isAdresGegevens.value ||
		isSalaris.value ||
		isAcquisitie.value ||
		isKwaliteit.value
	);
});

const monospace = inject("monospace");
const isDarkModePreferred = inject("isDarkModePreferred");
</script>
