<template>
	<ul class="challenges" v-if="!pending && data">
		<li v-for="(challenge, index) in data.data">
			<h3>{{ challenge.titel }}</h3>

			<Score
				:size="80"
				:min="challenge.minimumScore"
				:max="challenge.maximumScore"
				:model-value="challenge.huidigeScore"
				:goal-value="challenge.doelScore" />

			<p class="omschrijving">{{ challenge.omschrijving }}</p>
		</li>
	</ul>
</template>

<script setup>
const {data, pending} = await useCRUD(() => {
	return {
		entity: "Challenge",
		type: "select",
		filter: {
			start: {$past: true},
			deadline: {$future: true}
		}
	};
});
</script>

<style scoped>
.challenges {
	display: flex;
	width: 100%;
	flex-direction: row;
	margin: 0;
	padding: 0;
}

.challenges > li {
	display: flex;
	flex-direction: column;
	align-items: center;
	list-style: none;
	margin: 0;
	padding: 5px;
	margin-right: 15px;
	border-radius: 7px;
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
	background-color: var(--popped-background-color);
	width: 170px;
	min-height: 150px;
	overflow: hidden;
}

.challenges > li h3 {
	margin: 0;
	margin-bottom: 3px;
	padding: 3pt;
	font-size: 12pt;
	color: var(--heading-color);
	text-align: center;
}

.challenges > li > svg {
	flex-shrink: 0;
}

p.omschrijving {
	font-size: 9pt;
	text-align: center;
}
</style>
